/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppGeneralPermission = typeof AppGeneralPermission[keyof typeof AppGeneralPermission];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppGeneralPermission = {
  'is-admin': 'is-admin',
  'view-audit-logs': 'view-audit-logs',
  'download-audit-logs': 'download-audit-logs',
  'view-all-users': 'view-all-users',
  'create-project-bfrmp': 'create-project-bfrmp',
  'create-project-statewide-snapshot': 'create-project-statewide-snapshot',
  'create-project-statewide-maxfuels': 'create-project-statewide-maxfuels',
  'create-project-annual-works-plan-forecast': 'create-project-annual-works-plan-forecast',
  'create-project-annual-works-plan-evaluation': 'create-project-annual-works-plan-evaluation',
  'create-project-modelled-impact-time': 'create-project-modelled-impact-time',
  'create-project-individual-treatment-comparison': 'create-project-individual-treatment-comparison',
  'create-project-individual-treatment-comparison-brigade': 'create-project-individual-treatment-comparison-brigade',
  'view-global-inputs': 'view-global-inputs',
  'view-npws-inputs': 'view-npws-inputs',
  'download-inputs': 'download-inputs',
  'download-inputs-area-of-interest': 'download-inputs-area-of-interest',
  'upload-global-inputs': 'upload-global-inputs',
  'upload-npws-inputs': 'upload-npws-inputs',
  'upload-non-global-npws-inputs': 'upload-non-global-npws-inputs',
  'download-npws-inputs': 'download-npws-inputs',
  'upload-non-global-inputs': 'upload-non-global-inputs',
  'archive-inputs': 'archive-inputs',
  'view-weatherzones': 'view-weatherzones',
  'create-weatherzones': 'create-weatherzones',
  'archive-weatherzones': 'archive-weatherzones',
  'view-weather-library': 'view-weather-library',
  'create-weather-library': 'create-weather-library',
  'edit-weather-library': 'edit-weather-library',
  'view-bfmcs': 'view-bfmcs',
  'create-bfmcs': 'create-bfmcs',
  'archive-bfmcs': 'archive-bfmcs',
  'edit-bfmcs': 'edit-bfmcs',
  'view-notification-settings': 'view-notification-settings',
  'edit-notification-settings': 'edit-notification-settings',
  'view-permissions': 'view-permissions',
  'view-settings-page-jurisdictions': 'view-settings-page-jurisdictions',
  'view-settings-page-inputs': 'view-settings-page-inputs',
  'view-settings-page-configurations': 'view-settings-page-configurations',
} as const;
